h1.page-header {
  color: $grey;
  font-weight: bold;
  text-align: center;
  margin-top: 0;
  border-bottom: none;
  @media (max-width: $screen-sm-min) {
    margin-top: 0;
  }
}

.main-container {
  margin-top: 4em;
}

.page-user-login,
.page-user-register,
.page-user-password {
  .tabs--primary {
    display: none;
  }
}

.features {
  .row{
    margin-bottom: 4em;
    &:nth-child(even) {
      .image {
        float: right;
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
    h2 {
      font-size: 32px;
      margin-top: 10px;
      font-weight: bold;
    }
    p {
      margin-bottom: 1.5em;
    }
    ul {
      @include check-list();
    }
  }
}
.view-video {
  .view-header {
    margin-bottom: 6em;
  }
  .views-row {
    margin-bottom: 2em;
  }
  .title {
    color: $blue;
    font-size: 1.2em;
  }
  .descr {
    padding: 0 3em;
  }
  .embedded-video {
    iframe {
      width: 100%;
      height: auto;
    }
  }
}

.node-page {
  .field-name-field-sections {
    margin-top: 3em;
  }
}

.node-support_ticket-form {
  .panel.form-wrapper {
    border: none;
    legend {
      display: none;
    }
    .panel-body {
      padding: 0;
      table {
        .btn {

        }
      }
      .file-widget {
        .form-file {
          float: left;
          width: 85%;
        }
      }
      .btn {
        width: auto;
        padding: 5px 10px;
      }
    }
  }
}

#support-page {
  .text-center {
    padding: 0 4em;
    @media (max-width: $screen-sm-min) {
      padding: 0 1em;
    }
  }
}
#downloads-page {
  margin-top: 3em;
  p {
    margin-bottom: 20px;
  }
}

.field-name-field-sections {
  > .field-item {
    margin-bottom: 3em;
  }
}

.pricing_table {
  @include clearfix();
  font-size: 16px;
  margin-bottom: 2em;
  .column {
    float: left;
    margin-right: 1em;
    text-align: center;
    width: 23%;
    border: 2px solid $form-border-color;
    &.best {
      border-color: $blue;
      .bottom {
        border-color: $blue;
      }
    }
    &:first-child {
      text-align: right;
      width: 22%;
      border: 2px solid transparent;
      margin-left: 1em;
      .rows {
        .table-row {
          margin-left: 0;
          padding: 0 1em;
        }
      }
      .bottom {
        border-top: 2px solid transparent;
      }
    }
    &:last-child {
      margin-right: 0;
      .rows {
        .table-row {
          margin-right: 0;
          padding-left: 3em;
          .badge-wrapper {
            right: 0;
          }
        }
      }
    }
    .header {
      min-height: 110px;
      color: $blue;
      font-weight: bold;
      padding-top: 0.5em;
      em {
        display: block;
        font-style: normal;
        font-size: 1.3em;
      }
      span {
        font-weight: normal;
        color: $grey;
        display: block;
      }
    }
    .rows {
      .table-row {
        z-index: -1;
        overflow: hidden;
        position: relative;
        margin: 0 -0.63em;
        padding: 0 2em;
        vertical-align: middle;
        line-height: 48px;
        height: 48px;
        &:nth-child(odd) {
          background-color: $form-border-color;
        }
        .badge-wrapper {
          position: absolute;
          top: 0;
          right: 8px;
          width: 48px;
          height: 48px;
          overflow: hidden;
          .corner {
            color: $black;
            position: absolute;
            top: -11px;
            right: -77px;
            transform: rotate(45deg);
            line-height: 50px;
            background-color: $red;
            display: inline-block;
            width: 150px;
            height: 50px;
            padding: 12px 0 0 50px;
            text-align: left;
          }
        }
        .check {
          display: inline-block;
          @include check-mark();
        }
      }
    }
    .bottom {
      margin: -2px;
      background-color: $white;
      border-top: 2px solid $form-border-color;
      padding: 1em 0;
      font-size: 0.7em;
      .btn {
        width: auto;
        display: inline-block;
        background-color: $blue;
        padding: 0.15em 2.5em;
        color: $white;
      }
    }
  }

  @media (max-width: $screen-xs-max) {
    .column {
      width: 80%;
      margin: 0 auto !important;
      float: none;
      &:first-child {
        display: none;
      }
      .rows {
        .table-row {
          margin: 0;
          &.price {
            text-align: right;
          }
          .row-label {
            float: left;
          }
          .check {
            float: right;
            &:before {

            }
          }
          .badge-wrapper {
            right: 0;
          }
        }
      }
      .bottom {
        .btn {
          font-size: 2em;
        }
      }
    }
  }
}
.pricing_table_footer {
  p {
    line-height: 2em;
  }
}
.below-content {
  .section-block {
    @media (min-width: $screen-sm-min) {
      padding: 40px 0;
    }
    h2 {
      @media (min-width: $screen-sm-min) {
        margin-bottom: 2em;
      }

      font-weight: 600;
    }
    &:nth-child(odd) {
      background-color: $white2;
    }
    .cito {
      padding: 20px 15px;
      background: $white;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      margin-bottom: 1em;
    }
    .info {
      color: $grey;
      strong, span {
        display: block;
      }
      strong {
        font-weight: bold;
        font-size: 18px;
      }
      span {
        font-weight: 300;
      }
    }
  }
  .our-clients-say-block {
    text-align: center;
    max-width: 1200px;
    @media (max-width: $screen-sm-max) {
      > p {
        display: none;
      }
    }
  }
}
.pricing_table .column .rows .table-row .badge-wrapper .corner {
  background-color: #7ed321;
  font-size: 14px;
  font-weight: bold;
  top:-12px;
  right:-75px;
}

.search-content-wrapper {
  .search-form-wrapper {
    position: relative;
  }
  .btn-wrapper {
    position: absolute;
    top: 3px;
    right: 4px;
  }
  .btn {
    font-size: 14px;
    border-radius: 1.5em;
    padding: 6px 24px;
  }
}
.faq-section {
  h2 {
    margin-bottom: 1em;
  }
  .item {
    margin-bottom: 1em;
    a {
      color: $grey;
      text-decoration: underline;
    }
  }
}
.category-nav {
  list-style: none;
  li {
    a {
      color: $grey;
      &:hover {
        text-decoration: none;
      }
    }
    &.active {
      a {
        color: $blue;
      }
    }
  }
}

.page-success {
  .alert.alert-block.messages {
    display: none !important;
  }
}

.section{
  &.cta-block {
    text-align: center;
    h1 {
      margin-bottom: 1em;
      font-weight: bold;
    }
  }
  &.features-block {
    text-align: center;
    margin-bottom: 4em;
    h2 {
      font-weight: bold;
    }
    .icon-wrapper {
      img {
        display: block;
        margin: 0 auto;
      }
    }
  }
  &.feature-block {
    padding-bottom: 4em;
    .image-wrapper {
      margin-top: 2em;
    }
    ul {
      @include check-list();
    }
  }
}
.user-profile-form {
  .form-actions {
    button {
      margin: 0 1em;
    }
  }
}
.page-buy {
  h1 {
    margin-bottom: 1.5em;
  }
  #kosma-shop-buy-form {
    padding: 0 3em 3em;
    .form-actions {
      margin-top: 2em;
      text-align: center;
      button {
        width: 200px;
        margin: 0 1em;
      }
    }
    .form-wrapper {
      .form-item-product {
        label{
          font-weight: bold;
        }
      }
      p {
        margin-left: 3em;
        font-weight: 600;
      }
    }
  }
  @include kosma-radio();
}
.page-checkout {
  .checkout-buttons {
    text-align: center;
    border: none;
    box-shadow: none;
    clear: both;
    .checkout-continue {
      @extend .blue-btn;
    }
  }
  .commerce_payment {
    .form-type-radio {
      @include kosma-radio();
    }
    .commerce-credit-card-expiration {
      @include clearfix();
      width: 50%;
      float: left;
      label {
        width: 100%;
      }
      .form-select {
        width: 100px;
        float: left;
      }
      .commerce-month-year-divider {
        float: left;
      }
    }
    .form-item-commerce-payment-payment-details-credit-card-code {
      width: 50%;
      float: left;
      input {
        width: 100px;
      }
    }
    .form-item-commerce-payment-payment-details-credit-card-cardonfile-store {
      width: 100%;
      clear: left;
    }
  }
}
.page-user-cards-add {
  #commerce-stripe-cardonfile-create-form {
    @media (min-width: $screen-xs-max) {
      width: 60%;
      margin: 0 auto;
    }
  }
  .form-type-radio {
    @include kosma-radio();
  }
  .commerce-credit-card-expiration {
    @include clearfix();
    width: 50%;
    float: left;
    label {
      width: 100%;
    }
    .form-select {
      width: 100px;
      float: left;
    }
    .commerce-month-year-divider {
      float: left;
    }
  }
  .form-item-credit-card-code {
    width: 50%;
    float: left;
    input {
      width: 100px;
    }
  }
  .form-item-credit-card-cardonfile-instance-default {
    width: 100%;
    clear: left;
  }
}

.page-user-license-and-billing {
  #edit-subscription {
    .form-wrapper {
      p {
        margin-left: 3em;
      }
    }
  }
  .form-type-radio {
    @include kosma-radio();
  }
  .form-type-item {
    label.control-label {
      display: block;
      text-align: center;
    }
    p {
      margin-bottom: 0;
      text-align: center;
    }
  }
  .form-actions {
    text-align: center;
  }
  #edit-billing-address {
    h2 {
      text-align: center;
    }
  }
}
#views-form-commerce-cart-form-default {
  .views-table {
    tr > td {
      vertical-align: middle;
    }
  }
}

/* Tutorials Page */
.page-get-support-tutorials {
  .col-sm-3 h2 {
    font-size: 26px;
  }
  .col-sm-9 h2 {
    font-size: 26px;
  }
  .category-nav {
    padding-left: 0;
  }
  .col-sm-3 {
    width:20%;
  }
  .col-sm-9 {
    width:80%;
  }
  .screensteps-content p {
    color:rgb(121, 121, 121);
    margin-top:15px;
    margin-bottom:15px;
  }
  .screensteps-content img {
    max-width:100%;
    height:auto;
  }
  .screensteps-content {
    margin-right:0;
  }
}

/* FAQ Page */
.page-get-support-faq {
  .collapse.in {
    background-color: #f8f8f8;
    padding:15px;
    margin-bottom:15px;
  }

  .tab-pane.active p {
    margin-bottom:10px;
    margin-top: 10px;
  }
}

.view-commerce-cart-form,
#commerce-checkout-form-checkout,
#commerce-checkout-form-review,
#user-login,
#user-pass,
#user-register-form {
  width: 80%;
  margin: 0 auto;
}

.page-support-tickets {
  #support-page-form {
    width: 80%;
    margin: 1em auto;
  }
  #support-page-form {
    .new-ticket-btn {
      text-align: center;
      a {
        margin-bottom: 1em;
        display: inline-block;
      }
    }
  }
}

/* Front Page */

h3 {
  margin-top: 0;
}
.cta-block {
  margin-top: 60px;
  margin-bottom: 70px;
}
.cta-wrapper {
  margin-top: 3em;
}
.section.feature-block {
  padding-bottom: 2em;
}
.below-content {
  .section-block {
    padding-top: 1em;
    h2 {
      margin-bottom: 1em;
    }
  }
}


/* Forms */
.user-profile-form {
  width:60%;
  margin: 0 auto;
}
.contact-form {
  width:60%;
  margin: 0 auto;
}
.node-form.node-support_ticket-form {
  width:60%;
  margin: 0 auto;
}
/*.form-wrapper.form-group  {
  width:80%;
  margin-left:10%;
  margin-bottom:0;
}*/
#edit-subscription  {
  width:100%;
  margin-left:0;
}
#edit-billing-address  {
  width:100%;
  margin-left:0;
}
/*.g-recaptcha {
  margin-left:10%;
}*/
.form-actions.form-wrapper .btn {
  margin-top:20px;
  margin-right:20px;
  min-width:30%
}

#customer-profile-billing-ajax-wrapper {
  margin-bottom: 2em;
}
.customer_profile_billing {
  button.form-submit {
    padding: 5px 15px;
    margin-left: 2em;
    &:hover {
      background-color: transparent;
    }
  }
}

.page-thank-you .node-page .media .content img,
.page-besten-dank .node-page .media .content img{
  margin: 0 auto;
}

.page-user-license-and-billing {
  h2 {
    margin-top: 2em;
    margin-bottom: 1em;
  }
  #edit-subscription {
    h2 {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  #edit-billing-address {
    fieldset {
      border: none;
      legend { display: none; }
      .panel-body {padding: 0; }
    }
    .addressfield-container-inline > div.form-item {
      margin-bottom: 15px;
    }
  }
}

.node-type-blog-post,
.view-blog {
  .img-responsive {
    width: 100%;
    margin-bottom: 2em;
  }
}

.view-blog {
  .views-row {
    padding-bottom: 1em;
    margin-bottom: 2em;
    border-bottom: 1px solid #e7e7e7;
  }
}

.node-type-blog-post {
  .meta,
  .field-name-body {
    margin-bottom: 2em;
  }
}

.sidebar-second {
  aside {
    margin-top: 4em;
    h2 {
      font-size: 20px;
    }
  }
}
