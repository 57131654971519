#block-locale-language {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}

#block-lang-dropdown-language {
  .form-select {
    visibility: hidden;
    @media (max-width: $screen-xs-max) {
      visibility: visible;
      width: 80px !important;
    }
  }
  .chosen-container {
    .chosen-single {
      border: none;
      background: none;
      box-shadow: none;
      font-size: 16px;
    }
  }
}

.navbar-default .navbar-nav>.active>a,
.navbar-default .navbar-nav>.active>a:hover,
.navbar-default .navbar-nav>.active>a:focus {
  background-color: transparent;
}

#navbar {
  padding-top: 18px;
  .logo {
    max-width: 180px;
    @media (max-width: $screen-sm-max) {
      max-width: 150px;
    }
    img {
      width: 100%;
      height: auto;
      display: block;
    }
  }
  nav.navbar {
    ul {
      padding-top: 8px;
      @media (max-width: $screen-xs-max) {
        margin: 10px;
      }
      > li {
        margin-right: 54px;
        @media (max-width: $screen-sm-max) {
          margin-right: 1em;
        }
        &:last-child {
          margin-right: 0;
        }
        > a {
          padding: 10px 0;
          font-weight: 600;
        }
      }
    }
    @media (max-width: $screen-xs-max) {
      font-size: 1.2em;
      float: left;
    }
  }
  .nav-pills {
    a.dropdown-toggle {
      color: $blue;
      border-color: $blue;
      padding: 0;
      margin-left: 1em;
      &:hover {
        background: transparent;
        opacity: 0.8;
      }
    }

  }
  @media (max-width: $screen-xs-max) {
    .nav-pills {
      margin-top: 1em;
      .dropdown-menu {
        right: 0;
        left: auto;
      }
    }
  }

  .navbar.navbar-right {
    padding-top: 10px;
    margin-right: 0;
    @media (max-width: $screen-xs-max) {
      float: right;
      .region.region-navigation {
        float: none !important;
      }
      .nav.navbar-nav {
        margin: 1em 0;
        li {
          margin: 1em 0 0;
        }
      }
    }
  }
  .menu.secondary {
    margin-left: 0.5em;
    a {
      border: 2px solid $green;
      border-radius: 2em;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 16px;
      margin: 0 5px;
      &.active {
        background-color: $green;
        color: $white;
      }
    }
    .sign-up-link {
      a {
        padding: 5px 7px;
      }
    }
    .login-link {
      a {
        padding: 5px 14px;
      }
    }
  }
}

.navbar .menu.secondary .sign-up-link a {
  background-color:#7ed321;
}

.lang-dropdown-select-language {
  border-radius: 1em;
}
