@mixin question-mark{
  @include clearfix();
  &:before {
    content: " ";
    border-radius: 50%;
    background-image: url("../images/question.png");
    display: block;
    float: left;
    width: 32px;
    height: 32px;
    vertical-align: middle;
    line-height: 32px;
    text-align: center;
    color: $blue;
    margin-right: 1em;
    margin-bottom: 1em;
  }
}

@mixin check-mark{
  &:before {
    border-radius: 50%;
    background-image: url("../images/check.png");
    content: " ";
    display: block;
    float: left;
    width: 32px;
    height: 32px;
    vertical-align: middle;
    line-height: 32px;
    text-align: center;
    color: $blue;
  }
}

@mixin check-list {
  list-style: none;
  padding: 0;
  li {
    font-weight: 600;
    line-height: 30px;
    vertical-align: middle;
    margin-bottom: 20px;
    @include check-mark();
    &:before {
      margin-right: 1em;
    }
  }
}


@mixin kosma-radio {
  .control-label {
    display: block;
    position: relative;
    padding-left: 52px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .control-label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    visibility: hidden;
  }

  .control-label .checkmark {
    position: absolute;
    top: 3px;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 50%;
  }

  .control-label:hover input ~ .checkmark {
    background-color: #ccc;
  }

  .control-label input:checked ~ .checkmark {
    background-color: $white;
    border: 2px solid $blue;
  }

  .control-label .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .control-label input:checked ~ .checkmark:after {
    display: block;
  }

  .control-label .checkmark:after {
    top: 7px;
    left: 7px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: $blue;
  }
}
