
footer.footer {
  background-color: $white2;
  margin-top:20px;
  .copy {
    margin-top: 40px;
    font-weight: lighter;
    color: $light-grey;
  }
  .block-menu {
    h2 {
      color: $grey;
      text-transform: uppercase;
      font-size: 15px;
      font-weight: bold;
    }
    ul.menu {
      > li {
        > a {
          color: $grey;
          padding: 10px 0 0;
          line-height: 15px;
          font-size: 15px;
          &:hover,
          &:focus {
            background-color: transparent;
          }
        }
      }
    }
  }
  .block-block {
    text-align: right;
  }
  .region-footer {
    @include clearfix();
  }
  @media (max-width: $screen-sm-min) {
    .col-sm-3 {
      &.block-menu {
        float: left;
        width: 33%;
      }
      &.block-block {
        clear: both;
        width: 100%;
        text-align: center;
      }
    }
  }
  h2 {
    margin:0;
  }
  p {
    font-size:12px;
  }
  img {
    margin-top:10px;
  }
}
