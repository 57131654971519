html, body {
  font-family: $font-family;
  font-size: 18px;
  line-height: 30px;
  font-weight: normal;
  color: $grey;
  height: 100%;
}
.wrapper {
  min-height: calc(100vh - 300px);
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: $font-family;
}
h1, h2, h3, h4,
.h1, .h2, .h3, .h4 {
  font-weight: bold;
}
h1, .h1 {
  font-size: 45px;
  line-height: 60px;
}
h2, .h2 {
  font-size: 38px;
  line-height: 45px;
}
h3, .h3 {
  font-size: 24px;
  text-transform: uppercase;
  color: $blue;
}

p {
  margin-bottom: 40px;
}

body.navbar-is-fixed-top {
  padding-top: 91px;
}

.element-invisible {
  visibility: hidden;
  height: 0;
}

#skip-link {
  visibility: hidden;
  height: 0;
}

#page-header {
  background-color: $blue;
}

label.control-label {
  color: $grey;
}
.form-control,
input.form-text {
  border-radius: 0;
  height: 40px;
  border-color: $form-border-color;
}
.form-textarea-wrapper textarea {
  border-radius: 0;
  border-color: $form-border-color;
}

.btn {
  display: inline-block;
  margin: 0 auto;
  border-radius: 2em;
  padding: 12px 24px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 20px;
  @media (max-width: $screen-xs-max) {
    margin-bottom: 1em;
    font-size: 1em;
  }
}
.green-btn {
  background-color: $green;
  color: $white;
  &:hover {
    background-color: $green;
    color: $white;
    opacity: 0.8;
  }
}
.blue-btn {
  background-color: $blue;
  color: $white;
  &:hover {
    background-color: $blue;
    color: $white;
    opacity: 0.8;
  }
}
.red-btn {
  background-color: $red;
  color: $white;
  &:hover {
    background-color: $red;
    color: $white;
    opacity: 0.8;
  }
}

.rounded-images {
  margin-bottom: 2em;
  .file {
    .content {
      border-radius: 50%;
      border: 2px solid $blue;
      overflow: hidden;
      display: inline-block;
    }
  }
  img {
    display: inline-block;
  }
  .info {
    text-align: center;
    h2 {
      font-size: 1.2em;
      margin-bottom: 0;
    }
  }
}

.cta-block {
  margin: 5em 0;
  h2 {
    font-weight: bold;
  }
}
.cta-wrapper {
  margin-top: 5em;
}

.views-slideshow-cycle-main-frame {
  margin: 0 auto;
}

/* help block styling */
.help-block {
  line-height:normal;
}

/* some button styling */
.btn.btn-default.form-submit {
  background-color:#7ed321;
  color:white;

}
.blue-btn.btn.btn-default.form-submit {
  background-color:#009fda;
  color:white;
}
.red-btn.btn.btn-default.form-submit {
  background-color:red;
  color:white;
}

.checkout-buttons .checkout-cancel,
.checkout-buttons .checkout-back {
  margin-left: 1em;
  padding: 5px 15px;
}

mark {
  background: orange;
  color: inherit;
  padding: 0;
}
