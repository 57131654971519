$font-family: 'Source Sans Pro', sans-serif;

$blue: #009fda;
$grey: #797979;
$light-grey: #adadad;
$green: #7ed321;
$red: #FF0000;
$black: #000000;
$white: #ffffff;
$white2: #fbfbfb;
$form-border-color: #A9A9A9;
