.heading-1 {
  font-weight: bold;
  font-size: 45px;
  line-height: 55px;
}
.heading-2 {
  font-weight: bold;
  font-size: 38px;
  line-height: 45px;
}
.heading-3 {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
}
.heading-4 {
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
}
.intro {
  font-size: 22px;
  line-height: 30px;
  p {
    font-size: 20px;
  }
}
.testimonial {
  font-size: 14px;
}

p.text-center {
  font-size: 20px;
}
