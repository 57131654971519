.entity-paragraphs-item {
  h2 {
    font-weight: 600;
  }
}
.paragraphs-item-sub-pages {
  .paragraphs-item-sub-page {
    margin-bottom: 1em;
    min-height: 220px;
    @media (max-width: $screen-sm-min) {
      min-height: auto;
      width: 80%;
      margin: 0 auto 2em;
    }
    text-align: center;
    .field-name-field-title {
      font-size: 24px;
      font-weight: bold;
      a {
        color: $blue;
        &:hover {
          text-decoration: none;
        }
      }
    }
    .field-name-field-description {
      font-size: 16px;
      line-height: 30px;
    }
  }
}
.paragraphs-item-cta-block {
  text-align: center;
  p {
    max-width: 75%;
    margin: 0 auto;
  }
  .field-name-field-link {
    margin: 5em 0;
  }
}
.paragraphs-item-3-column-items-block {
  text-align: center;
  margin-bottom: 6em;
  .field-name-field-items {
    margin-bottom: 4em;
  }
  .blue-btn {
    display: inline-block;
    width: auto;
    background: transparent;
    color: $blue;
    border: 2px solid $blue;
    padding: 12px 24px;
  }
}
.paragraphs-item-mini-feature-item {
  .field-item {
    img {
      margin: 0 auto;
    }
  }
  h3 {
    margin-top: 0;
    color: $blue;
  }
  p {
    max-width: 70%;
    margin: 0 auto;
  }
}
.paragraphs-item-content-block-image {
  h2 {
    margin-bottom: 1em;
  }
  .field-name-field-image {
    margin-top: 2em;
  }
  ul {
    @include check-list();
  }
}
.paragraphs-item-faq-section {
  h2 {
    text-align: center;
  }
  .field-type-paragraphs {
    >.field-item {
      margin-bottom: 1em;
    }
  }
}
.paragraphs-item-faq-item {
  text-align: left;
  @include question-mark();
  >.content {
    float: left;
    width: 80%;
  }
}
